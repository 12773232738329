import React, { useEffect, useState, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
// #region Components
import Input from "shared/components/FormElements/Input";
import CustomSelect from "shared/components/FormElements/Select";
import DatePicker from "shared/components/FormElements/DatePicker";
import Button from "shared/components/FormElements/Button";
import Card from "shared/components/UIElements/Card";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Modal from "shared/components/UIElements/Modal";
import NotFound from "shared/components/UIElements/NotFound";
import DeleteModal from "components/shared/DeleteModal";
import ItemGenerated from "components/shared/ItemGenerated";
import FormFooter from "shared/components/FormElements/FormFooter";
// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE, VALIDATOR_NONE } from "shared/util/validators";
import { paymentMethods, banks, paymentTypes } from "shared/util/types";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

const WithdrawItem = ({ fixedContract, itemId, isNewItem, onClose, InsertItem, DeleteItem, UpdateItem, gotoItem }) => {
  const { t } = useTranslation();
  const [isItemLoaded, setIsItemLoaded] = useState(false);
  const [loadedItem, setLoadedItem] = useState();
  const [generatedItemId, setGeneratedItemId] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { withdraws, contractsOptions, shiftPersonelServiceOptions, userOptions, shifts } = useContext(StoreContext);

  const itemType = "withdraw";
  const endPointUpdate = "withdraws";
  const endPointDelete = "withdraws";
  const endPointCreate = "withdraws";

  const [formState, inputHandler] = useForm(
    {
      contract: { value: null, isValid: true },
      date: { value: "", isValid: true },
      paymentMethod: { value: "", isValid: true },
      paymentType: { value: "", isValid: true },
      amount: { value: null, isValid: true },
      bank: { value: null, isValid: true },
      withdrawNo: { value: null, isValid: true },
      official: { value: true, isValid: true },
    },
    true
  );

  const fetchItem = useCallback(async () => {
    setIsItemLoaded(false);
    try {
      const currentWithdraw = withdraws.find((withdraw) => withdraw?.id === itemId);
      setLoadedItem(currentWithdraw);
    } catch (err) {}
    setIsItemLoaded(true);
    // eslint-disable-next-line
  }, [itemId]);

  useEffect(() => {
    fetchItem();
  }, [itemId, fetchItem]);

  const itemUpdateSubmitHandler = async (event) => {
    event.preventDefault();

    const endpoint = isNewItem
      ? `${process.env.REACT_APP_BACKEND_URL}/${endPointCreate}`
      : `${process.env.REACT_APP_BACKEND_URL}/${endPointUpdate}/${itemId}`;

    const postType = isNewItem ? "POST" : "PATCH";

    const infoToBeSent = {
      contract: formState.inputs.contract.value,
      date: formState.inputs.date.value,
      paymentMethod: formState.inputs.paymentMethod.value,
      paymentType: formState.inputs.paymentType.value,
      amount: formState.inputs.amount.value,
      bank: formState.inputs.bank.value,
      withdrawNo: formState.inputs.withdrawNo.value,
      official: true,
    };

    try {
      const responseData = await sendRequest(endpoint, postType, JSON.stringify(infoToBeSent));

      if (responseData?.[itemType]) {
        if (isNewItem) {
          InsertItem(responseData[itemType]);
          setGeneratedItemId(responseData[itemType].id);
        } else {
          UpdateItem(responseData[itemType]);
          window.toast.success(t("updateSuccess"));
        }
      }
    } catch (err) {
      if (err?.message) {
        window.toast.error(err.message);
      } else {
        window.toast.error(t("actionFailed"));
      }
    }
  };
  //standard code
  if (isItemLoaded && !isNewItem && !loadedItem && !error) {
    return <NotFound item={itemType}></NotFound>;
  }

  if (generatedItemId) {
    return (
      <ItemGenerated
        itemType={itemType}
        onGotoNewItem={() => gotoItem(generatedItemId)}
        onCreateNewItem={() => setGeneratedItemId(null)}
        onClose={() => {
          setGeneratedItemId(false);
          onClose();
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <DeleteModal
        endPoint={endPointDelete}
        itemType={itemType}
        itemId={itemId}
        onDeleted={() => {
          DeleteItem(itemId);
          onClose();
        }}
        onClose={() => setShowConfirmModal(false)}
        showModal={showConfirmModal}
      />

      {isItemLoaded && (
        <form className="form-container" onSubmit={itemUpdateSubmitHandler}>
          <CustomSelect
            options={contractsOptions}
            id="contract"
            onInput={inputHandler}
            label="Öğrenci Sözleşmesi"
            initialValid={true}
            validators={[VALIDATOR_NONE()]}
            initialValue={
              !fixedContract
                ? contractsOptions.find(
                    (contract) => contract.value.toString() === loadedItem?.contract?.id?.toString()
                  )
                : contractsOptions.find((contract) => contract.value.toString() === fixedContract?.id?.toString())
            }
            disabled={fixedContract}
          ></CustomSelect>

          <DatePicker
            id="date"
            onInput={inputHandler}
            label="İade Tarihi"
            initialValid={true}
            validators={[VALIDATOR_REQUIRE()]}
            initialValue={loadedItem?.date?.toString()}
          ></DatePicker>

          <div className="horizontal-flex">
            <CustomSelect
              options={paymentMethods}
              id="paymentMethod"
              onInput={inputHandler}
              label="İade Ödeme Yöntemi"
              validators={[VALIDATOR_REQUIRE()]}
              initialValid={true}
              initialValue={paymentMethods.find(
                (paymentMethod) => paymentMethod?.value.toString() === loadedItem?.paymentMethod?.toString()
              )}
            ></CustomSelect>

            <CustomSelect
              options={paymentTypes}
              id="paymentType"
              onInput={inputHandler}
              label="İade Şekli"
              validators={[VALIDATOR_REQUIRE()]}
              initialValid={true}
              initialValue={paymentTypes.find(
                (paymentType) => paymentType.value.toString() === loadedItem?.paymentType?.toString()
              )}
            ></CustomSelect>
          </div>

          <div className="horizontal-flex">
            <CustomSelect
              options={banks}
              id="bank"
              onInput={inputHandler}
              label="Ödemenin Yapıldığı Banka"
              validators={[VALIDATOR_NONE()]}
              initialValid={true}
              initialValue={banks.find((bank) => bank.value.toString() === loadedItem?.bank?.toString())}
            ></CustomSelect>

            <Input
              id="receiptNo"
              element="input"
              type="text"
              label="Tahsilat Makbuz Numarası"
              validators={[VALIDATOR_NONE()]}
              errorText="Fatura no giriniz."
              initialValue={loadedItem?.withdrawNo}
              initialValid={true}
              onInput={inputHandler}
            />
          </div>

          <Input
            id="amount"
            element="input"
            type="text"
            label="Ödeme Tutarı"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Ödeme tutarını giriniz."
            initialValue={loadedItem?.amount}
            initialValid={isNewItem ? false : true}
            onInput={inputHandler}
          />

          <FormFooter
            isNewItem={isNewItem}
            onUpdate={itemUpdateSubmitHandler}
            onDelete={() => setShowConfirmModal(true)}
            isUpdating={isLoading}
            disabled={
              !formState.inputs?.contract?.value || !formState.inputs?.date?.value || !formState.isValid || isLoading
            }
          />
        </form>
      )}
    </React.Fragment>
  );
};

export default WithdrawItem;
