import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Layout from "pages/shared/Layout";

import { StoreContext } from "shared/context/store-context";
import { paymentMethods, paymentTypes, banks } from "shared/util/types";
import { getContractYearLabel } from "shared/util/relationaldata";

import { Update } from "@mui/icons-material";
import VehiclePaymentItem from "./VehiclePaymentItem";

const VehiclePayments = (props) => {
  const { t } = useTranslation();
  const itemType = "vehiclePayment";
  const listName = "main_vehiclePayments";

  const {
    vehiclePayments,
    vehicleRouteOptions,

    schoolOptions,

    receipts,
    selectedEducationYearId,
    DeleteVehiclePayment,
    InsertVehiclePayment,
    UpdateVehiclePayment,
  } = useContext(StoreContext);

  const [pageItems, setPageItems] = useState();

  useEffect(() => {
    // setPageItems(vehiclePayments.filter((item) => item?.contract?.year === selectedEducationYearId));
    setPageItems(vehiclePayments);
  }, [receipts, selectedEducationYearId, vehiclePayments]);

  let listColumns = [
    {
      field: "school",
      headerName: "Okul",
      width: 300,
      valueGetter: (params) =>
        schoolOptions.find((item) => item.value.toString() === params?.row?.school?.toString())?.label || "",
    },
    {
      field: "vehicleRoute",
      headerName: "Rota",
      width: 400,
      valueGetter: (params) => {
        try {
          return (
            vehicleRouteOptions.find((item) => item?.value?.toString() === params?.row?.vehicleRoute?.toString())
              ?.label || ""
          );
        } catch {
          return "";
        }
      },
    },

    { field: "owner", headerName: "Araç Sahibi", width: 300 },
    { field: "driver", headerName: "Sürücü", width: 300 },
    { field: "amount", headerName: "Ücret", width: 100 },
  ];

  return (
    <Layout
      itemType={itemType}
      items={pageItems}
      listName={listName}
      listColumns={listColumns}
      showSelectedYear={true}
      isAlwaysFullScreen={false}
      hideCreateNewItem={false}
      hideRightHeader={false}
      // hideTopBarWhenSelected={true}
      SelectedItemComponent={VehiclePaymentItem}
      NewItemComponent={VehiclePaymentItem}
      DeleteItem={DeleteVehiclePayment}
      InsertItem={InsertVehiclePayment}
      UpdateItem={UpdateVehiclePayment}
    />
  );
};

export default VehiclePayments;
