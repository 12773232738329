import React, { useEffect, useState, useContext, useCallback } from "react";
import { useHttpClient } from "shared/hooks/http-hook";
import { StoreContext } from "shared/context/store-context";
import { useTranslation } from "react-i18next";
import NotFound from "shared/components/UIElements/NotFound";
import ContractAnalyzer from "./ContractAnalyzer";
import ContractInfo from "./ContractInfo";

const ManagedContract = ({ isNewItem, itemId, onClose }) => {
  const { sendRequest } = useHttpClient();
  const { t } = useTranslation();
  const { contracts, receipts } = useContext(StoreContext);

  const [isItemLoaded, setIsItemLoaded] = useState(false);
  const [currentContract, setCurrentContract] = useState();
  const [currentReceipts, setCurrentReceipts] = useState([]);
  const [paidAmount, setPaidAmount] = useState(0);
  const [leftAmount, setLeftAmount] = useState(0);

  const endPointGet = "contracts";
  const itemType = "contract";

  const fetchItem = useCallback(async () => {
    try {
      if (isNewItem) {
        setIsItemLoaded(true);
        return;
      }
      debugger;
      const contract = contracts.find((contract) => contract.id === itemId);
      setCurrentContract(contract);
      setCurrentReceipts(receipts.filter((receipt) => receipt?.contract?.id === itemId));

      const totalPaid = receipts
        .filter((receipt) => receipt.contract?.id === itemId)
        .reduce((acc, receipt) => acc + receipt.amount, 0);
      setPaidAmount(totalPaid);

      setLeftAmount(contract.finalAmount - totalPaid);

      setIsItemLoaded(true);
    } catch (error) {
      console.log(error);
      window.toast.error(t("actionFailed"));
    }
  }, [contracts, isNewItem, itemId, receipts, t]);

  useEffect(() => {
    fetchItem();
  }, [itemId, fetchItem]);

  if (!isItemLoaded) {
    return <div></div>;
  }

  if (!currentContract) {
    return <NotFound item={itemType}></NotFound>;
  }

  return (
    <React.Fragment>
      {
        <div>
          <ContractInfo contract={currentContract} paidAmount={paidAmount} leftAmount={leftAmount} onClose={onClose} />
          <ContractAnalyzer
            contract={currentContract}
            onClose={onClose}
            // dropOffServices={currentShiftServicesGETOFF}
          />
        </div>
      }
    </React.Fragment>
  );
};

export default ManagedContract;
