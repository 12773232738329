import React, { useEffect, useState, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
// #region Components
import Input from "shared/components/FormElements/Input";
import CustomSelect from "shared/components/FormElements/Select";
import DatePicker from "shared/components/FormElements/DatePicker";
import Button from "shared/components/FormElements/Button";
import Card from "shared/components/UIElements/Card";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Modal from "shared/components/UIElements/Modal";
import NotFound from "shared/components/UIElements/NotFound";
import DeleteModal from "components/shared/DeleteModal";
import ItemGenerated from "components/shared/ItemGenerated";
import FormFooter from "shared/components/FormElements/FormFooter";
import { isOfficial } from "shared/util/types";
// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE, VALIDATOR_NONE } from "shared/util/validators";
import {
  paymentMethods,
  banks,
  paymentTypes,
  isSinglePaymentOptions,
  installmentCountOptions,
} from "shared/util/types";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

const VehiclePaymentItem = ({ itemId, isNewItem, onClose, InsertItem, DeleteItem, UpdateItem, gotoItem }) => {
  const { t } = useTranslation();
  const [isItemLoaded, setIsItemLoaded] = useState(false);
  const [loadedItem, setLoadedItem] = useState();
  const [generatedItemId, setGeneratedItemId] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { vehiclePayments, schoolOptions, vehicleRouteOptions } = useContext(StoreContext);

  const itemType = "vehiclePayment";
  const endPointUpdate = "vehiclepayments";
  const endPointDelete = "vehiclepayments";
  const endPointCreate = "vehiclepayments";

  const [formState, inputHandler] = useForm(
    {
      school: { value: null, isValid: true },
      vehicleRoute: { value: "", isValid: true },
      plate: { value: "", isValid: true },
      owner: { value: "", isValid: true },
      driver: { value: "", isValid: true },
      district: { value: "", isValid: true },
      startDate: { value: "", isValid: true },
      endingDate: { value: "", isValid: true },
      hours: { value: "", isValid: true },
      hostess: { value: false, isValid: true },
      weekend: { value: false, isValid: true },
      description: { value: "", isValid: true },
      amount: { value: 0, isValid: true },
    },
    true
  );

  const fetchItem = useCallback(async () => {
    setIsItemLoaded(false);
    try {
      const currentVehiclePayment = vehiclePayments.find((vehiclePayment) => vehiclePayment?.id === itemId);
      setLoadedItem(currentVehiclePayment);
    } catch (err) {}
    setIsItemLoaded(true);
  }, [itemId]);

  useEffect(() => {
    fetchItem();
  }, [itemId, fetchItem]);

  const itemUpdateSubmitHandler = async (event) => {
    event.preventDefault();

    const endpoint = isNewItem
      ? `${process.env.REACT_APP_BACKEND_URL}/${endPointCreate}`
      : `${process.env.REACT_APP_BACKEND_URL}/${endPointUpdate}/${itemId}`;

    const postType = isNewItem ? "POST" : "PATCH";

    const infoToBeSent = {
      school: formState.inputs.school.value,
      vehicleRoute: formState.inputs.vehicleRoute.value,
      plate: formState.inputs.plate.value,
      owner: formState.inputs.owner.value,
      driver: formState.inputs.driver.value,
      district: formState.inputs.district.value,
      startDate: formState.inputs.startDate.value,
      endingDate: formState.inputs.endingDate.value,
      hours: formState.inputs.hours.value,
      hostess: formState.inputs.hostess.value,
      weekend: formState.inputs.weekend.value,
      description: formState.inputs.description.value,
      amount: formState.inputs.amount.value,
    };

    try {
      const responseData = await sendRequest(endpoint, postType, JSON.stringify(infoToBeSent));

      if (responseData?.[itemType]) {
        if (isNewItem) {
          InsertItem(responseData[itemType]);
          setGeneratedItemId(responseData[itemType].id);
        } else {
          UpdateItem(responseData[itemType]);
          window.toast.success(t("updateSuccess"));
        }
      }
    } catch (err) {
      if (err?.message) {
        window.toast.error(err.message);
      } else {
        window.toast.error(t("actionFailed"));
      }
    }
  };
  //standard code
  if (isItemLoaded && !isNewItem && !loadedItem && !error) {
    return <NotFound item={itemType}></NotFound>;
  }

  if (generatedItemId) {
    return (
      <ItemGenerated
        itemType={itemType}
        // onGotoNewItem={() => gotoItem(generatedItemId)}
        onCreateNewItem={() => setGeneratedItemId(null)}
        // onClose={() => {
        //   setGeneratedItemId(false);
        //   onClose();
        // }}
      />
    );
  }

  return (
    <React.Fragment>
      <DeleteModal
        endPoint={endPointDelete}
        itemType={itemType}
        itemId={itemId}
        onDeleted={() => {
          DeleteItem(itemId);
          onClose();
        }}
        onClose={() => setShowConfirmModal(false)}
        showModal={showConfirmModal}
      />

      {isItemLoaded && (
        <form className="form-container" onSubmit={itemUpdateSubmitHandler}>
          <CustomSelect
            options={schoolOptions}
            id="school"
            onInput={inputHandler}
            label="Okul"
            initialValid={true}
            initialValue={schoolOptions.find((item) => item.value.toString() === loadedItem.school.toString())}
          ></CustomSelect>

          <CustomSelect
            options={vehicleRouteOptions}
            id="vehicleRoute"
            onInput={inputHandler}
            label="Servis"
            initialValid={true}
            initialValue={vehicleRouteOptions.find(
              (item) => item.value.toString() === loadedItem.vehicleRoute.toString()
            )}
          ></CustomSelect>

          <Input
            id="plate"
            element="input"
            type="text"
            label="Plaka"
            validators={[VALIDATOR_NONE()]}
            errorText="Plaka giriniz."
            initialValue={loadedItem.plate}
            initialValid={true}
            onInput={inputHandler}
          />

          <Input
            id="owner"
            element="input"
            type="text"
            label="Araç sahibi"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Araç sahibi ismi giriniz."
            initialValue={loadedItem.owner}
            initialValid={true}
            onInput={inputHandler}
          />

          <Input
            id="driver"
            element="input"
            type="text"
            label="Sürücü"
            validators={[VALIDATOR_NONE()]}
            errorText="Sürücü ismi giriniz."
            initialValue={loadedItem.driver}
            initialValid={true}
            onInput={inputHandler}
          />

          <Input
            id="district"
            element="input"
            type="text"
            label="Güzergah Bölgesi"
            validators={[VALIDATOR_NONE()]}
            errorText="Güzergah bölgesi giriniz."
            initialValue={loadedItem.district}
            initialValid={true}
            onInput={inputHandler}
          />

          <Input
            id="hours"
            element="input"
            type="text"
            label="Servis Saatleri"
            validators={[VALIDATOR_NONE()]}
            errorText="Servis saatlerini giriniz."
            initialValue={loadedItem.hours}
            initialValid={true}
            onInput={inputHandler}
          />

          <DatePicker
            id="startDate"
            onInput={inputHandler}
            label="Hizmet Başlangıç Tarihi"
            initialValid={true}
            validators={[VALIDATOR_NONE()]}
            initialValue={loadedItem.startDate.toString()}
            nonrequired={true}
          ></DatePicker>

          <DatePicker
            id="endingDate"
            onInput={inputHandler}
            label="Hizmet Bitiş Tarihi"
            initialValid={true}
            validators={[VALIDATOR_NONE()]}
            initialValue={loadedItem.endingDate.toString()}
            nonrequired={true}
          ></DatePicker>

          <CustomSelect
            options={isOfficial}
            id="hostess"
            onInput={inputHandler}
            label="Araçta hostes var mı?"
            initialValid={true}
            validators={[VALIDATOR_NONE()]}
            initialValue={isOfficial.find((item) => item.value.toString() === loadedItem.hostess.toString())}
          ></CustomSelect>

          <CustomSelect
            options={isOfficial}
            id="weekend"
            onInput={inputHandler}
            label="Hafta sonu çalışıyor mu?"
            initialValid={true}
            validators={[VALIDATOR_NONE()]}
            initialValue={isOfficial.find((item) => item.value.toString() === loadedItem.weekend.toString())}
          ></CustomSelect>

          <Input
            id="description"
            element="textarea"
            type="text"
            label="Notlar (Araca ait ek bilgileri burada tutabilirsiniz)"
            validators={[VALIDATOR_NONE()]}
            errorText="Servis saatlerini giriniz."
            initialValue={loadedItem.description}
            initialValid={true}
            onInput={inputHandler}
          />

          <Input
            id="amount"
            element="input"
            type="text"
            label="Toplam Ücret"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Hizmet bedelinin standart ücretini giriniz."
            initialValue={loadedItem.amount}
            initialValid={true}
            onInput={inputHandler}
          />
          <FormFooter
            isNewItem={isNewItem}
            onUpdate={itemUpdateSubmitHandler}
            onDelete={() => setShowConfirmModal(true)}
            isUpdating={isLoading}
            disabled={
              !formState.inputs?.school?.value ||
              !formState.inputs?.vehicleRoute.value ||
              !formState.isValid ||
              isLoading
            }
          />
        </form>
      )}
    </React.Fragment>
  );
};

export default VehiclePaymentItem;
