import React, { useState, useContext, useEffect } from "react";

import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
import { parseComplexDate } from "shared/util/util";
import Grid from "@mui/material/Grid";
import CustomSelect from "shared/components/FormElements/Select";
import Button from "shared/components/FormElements/Button";
import { useForm } from "shared/hooks/form-hook";
import DatePicker from "shared/components/FormElements/DatePicker";
import { VALIDATOR_REQUIRE } from "shared/util/validators";
import * as XLSX from "xlsx";
import ExcelJS from "exceljs";

import "./Admin.css";

const TransferReport = (props) => {
  const store = useContext(StoreContext);
  const auth = useContext(AuthContext);
  const { isLoading, sendRequest } = useHttpClient();
  const [today, setToday] = useState(null);
  const [calculatedTransfers, setCalculatedTransfers] = useState([]);
  const [initDateSelected, setInitDateSelected] = useState();
  const [finishDateSelected, setFinishDateSelected] = useState();

  const [storeTransfers, setStoreTransfers] = useState([]);
  const [storeVehicleRoutes, setStoreVehicleRoutes] = useState([]);
  const [storePersonelServices, setStorePersonelServices] = useState([]);

  const [savingVehicleRouteId, setSavingVehicleRouteId] = useState();

  const [defaultFormat, setDefaultFormat] = useState(true);

  const [schoolType, setSchoolType] = useState();

  const [formState, inputHandler] = useForm(
    {
      datestarter: { value: "", isValid: false },
      datefinisher: { value: "", isValid: false },
      school: { value: "", isValid: true },
    },
    false
  );

  const [formStateDriver, inputHandlerDriver] = useForm(
    {
      driver: { value: "", isValid: true },
    },
    true
  );

  const ItemEndPointType = "vehicleroutes";
  const personelServiceEndPointType = "personelservices";

  useEffect(() => {
    const todayString = new Date();
    const date = parseComplexDate(todayString);
    setToday(date);
    setInitDateSelected(date);
    setFinishDateSelected(date);

    setStoreTransfers(store.transfers);
    setStoreVehicleRoutes(store.vehicleRoutes);
    setStorePersonelServices(store.standartPersonelServices);
  }, [
    setToday,
    setInitDateSelected,
    setFinishDateSelected,
    store.transfers,
    store.vehicleRoutes,
    store.standartPersonelServices,
  ]);

  const createReport = async () => {
    let allTransfers = [];

    const currentSchool = store.schools.find((school) => school.id.toString() === formState.inputs.school.value);
    if (!currentSchool) {
      window.toast.error("Lütfen kurum seçiniz.");
      return;
    }

    const currentSchoolType = currentSchool.type;
    setSchoolType(currentSchoolType);

    const startDate = new Date(formState.inputs.datestarter.value);
    const endDate = new Date(formState.inputs.datefinisher.value);

    setInitDateSelected(parseComplexDate(startDate));
    setFinishDateSelected(parseComplexDate(endDate));

    allTransfers = storeTransfers.filter(
      (transfer) =>
        new Date(transfer.transferdate) >= startDate &&
        new Date(transfer.transferdate) <= endDate &&
        !transfer.done &&
        transfer.school.toString() === currentSchool.id.toString()
    );

    let calculatedTransfers = [];

    for (let m = 0; m < allTransfers.length; m++) {
      const currentTransfer = allTransfers[m];

      for (let i = 0; i < currentTransfer.vehicleRoutes.length; i++) {
        const currentVehicleRoute = storeVehicleRoutes.find(
          (vehicleRoute) => vehicleRoute._id.toString() === currentTransfer.vehicleRoutes[i].toString()
        );

        const currentPersonelService = storePersonelServices.find(
          (personelService) => personelService.id === currentTransfer.vehicleRoutes[i].toString()
        );

        let currentPeople = [];

        if (
          currentVehicleRoute &&
          currentVehicleRoute.routelocations &&
          currentVehicleRoute.routelocations.length > 1
        ) {
          currentPeople = [];
          for (let k = 1; k < currentVehicleRoute.routelocations.length; k++) {
            try {
              const currentPersonId = currentVehicleRoute.routelocations[k].student;
              const currentPersonTransferTime = currentVehicleRoute.routelocations[k].transfertime;
              const currentPersonName = store.students.find(
                (item) => item._id.toString() === currentPersonId.toString()
              ).name;

              currentPeople.push(
                currentPersonTransferTime ? currentPersonTransferTime + " - " + currentPersonName : currentPersonName
              );
            } catch {}
          }
        }

        if (currentPersonelService) {
          for (let k = 0; k < currentPersonelService.users.length; k++) {
            try {
              const currentPersonTransferTime = currentPersonelService.usersdetails.find(
                (userDetail) => userDetail.user.toString() === currentPersonelService.users[k].id
              )?.transfertime;
              const currentPersonName = currentPersonelService.users[k].name;
              const currentPersonId = currentPersonelService.users[k].id;
              const userClass =
                store.users.find((item) => item._id.toString() === currentPersonId.toString())?.cardId || "-";

              currentPeople.push(
                currentPersonTransferTime
                  ? currentPersonTransferTime + " - " + currentPersonName + " (" + userClass + ")"
                  : currentPersonName + " (" + userClass + ")"
              );
            } catch {}
          }
        }

        if (currentVehicleRoute || currentPersonelService) {
          calculatedTransfers.push({
            transferInfo: currentTransfer,
            route: currentVehicleRoute || currentPersonelService,
            people: currentPeople.sort(),
          });
        }
      }
    }

    let sortedTransfers = calculatedTransfers.sort((t1, t2) =>
      t1.transferInfo.transferdate + t1.transferInfo.transfertime.split(" ")[4].substring(0, 5) >
      t2.transferInfo.transferdate + t2.transferInfo.transfertime.split(" ")[4].substring(0, 5)
        ? 1
        : -1
    );

    setCalculatedTransfers(sortedTransfers);

    if (sortedTransfers.length === 0) {
      window.toast.error("Belirtilen tarih aralığında aktif transfer bulunamadı.");
    }
  };

  const peopleComponent = (people) => {
    if (!people || people.length === 0) {
      return <p>Yolcu bulunamadı.</p>;
    }

    return (
      <div>
        {people.map((person) => (
          <p key={person + Math.random()}> {person}</p>
        ))}
      </div>
    );
  };

  const saveDriverHandler = async (event, elementId, vehicleRouterId) => {
    event.preventDefault();

    if (schoolType === "2") {
      setSavingVehicleRouteId(vehicleRouterId);
      const selectedDriverId = formStateDriver.inputs[elementId].value;

      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/${personelServiceEndPointType}/${vehicleRouterId}`,
          "PATCH",
          JSON.stringify({
            drivers: [selectedDriverId],
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        store.UpdatePersonelServices();

        //await createReport();
        //setItemState(Date.now());

        let currentCalculatedTransfers = [...calculatedTransfers];

        let currentTransfer = currentCalculatedTransfers.find((transfer) => transfer.route._id === vehicleRouterId);

        let currentUpdatedVehicleRoute = storePersonelServices.find((route) => route._id === vehicleRouterId);
        currentUpdatedVehicleRoute.drivers[0] = store.driverOptions.find((driver) => driver.value === selectedDriverId);
        currentUpdatedVehicleRoute.drivers[0]._id = selectedDriverId;
        currentUpdatedVehicleRoute.drivers[0].id = selectedDriverId;
        currentUpdatedVehicleRoute.drivers[0].name = currentUpdatedVehicleRoute.drivers[0].label;
        currentTransfer.route = currentUpdatedVehicleRoute;

        for (let i = 0; i < currentCalculatedTransfers.length; i++) {
          if (currentCalculatedTransfers[i].route._id === vehicleRouterId) {
            currentCalculatedTransfers[i] = currentTransfer;
          }
        }
        setCalculatedTransfers(currentCalculatedTransfers);
      } catch (err) {}

      setSavingVehicleRouteId(null);
    } else {
      setSavingVehicleRouteId(vehicleRouterId);
      const selectedDriverId = formStateDriver.inputs[elementId].value;

      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${vehicleRouterId}`,
          "PATCH",
          JSON.stringify({
            driver: selectedDriverId,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        store.UpdateVehicleRoutes();

        //await createReport();
        //setItemState(Date.now());

        let currentCalculatedTransfers = [...calculatedTransfers];

        let currentTransfer = currentCalculatedTransfers.find((transfer) => transfer.route._id === vehicleRouterId);

        let currentUpdatedVehicleRoute = storeVehicleRoutes.find((route) => route._id === vehicleRouterId);
        currentUpdatedVehicleRoute.driver[0] = store.driverOptions.find((driver) => driver.value === selectedDriverId);
        currentUpdatedVehicleRoute.driver[0]._id = selectedDriverId;
        currentUpdatedVehicleRoute.driver[0].id = selectedDriverId;
        currentUpdatedVehicleRoute.driver[0].name = currentUpdatedVehicleRoute.driver[0].label;
        currentTransfer.route = currentUpdatedVehicleRoute;

        for (let i = 0; i < currentCalculatedTransfers.length; i++) {
          if (currentCalculatedTransfers[i].route._id === vehicleRouterId) {
            currentCalculatedTransfers[i] = currentTransfer;
          }
        }
        setCalculatedTransfers(currentCalculatedTransfers);
      } catch (err) {}

      setSavingVehicleRouteId(null);
    }
  };

  const driverComponent = (vehicleRoute) => {
    let currentDriverName = "";

    if (schoolType === "1") {
      try {
        currentDriverName = vehicleRoute.driver[0].name;
      } catch {
        return <p>Şoför bulunamadı.</p>;
      }
      if (!currentDriverName) {
        return <p>Şoför bulunamadı.</p>;
      }

      return defaultFormat ? (
        <div style={{ marginBottom: "10px" }}>
          <CustomSelect
            options={store.driverOptions}
            key={`vehiclerouterid_driver_${vehicleRoute._id}_${vehicleRoute.driver[0]._id}`}
            id={`vehiclerouterid_driver_${vehicleRoute._id}`}
            onInput={inputHandlerDriver}
            initialValue={store.driverOptions.filter((option) => vehicleRoute.driver[0]._id === option.value)}
            initialValid={true}
          ></CustomSelect>

          <Button
            type="button"
            onClick={(event) =>
              saveDriverHandler(event, `vehiclerouterid_driver_${vehicleRoute._id}`, vehicleRoute._id)
            }
            disabled={savingVehicleRouteId === vehicleRoute._id}
          >
            Kaydet
          </Button>
        </div>
      ) : (
        <div>
          <p>{currentDriverName}</p>
        </div>
      );
    } else {
      try {
        currentDriverName = vehicleRoute.drivers[0].name;
      } catch {
        return <p>Şoför bulunamadı.</p>;
      }
      if (!currentDriverName) {
        return <p>Şoför bulunamadı.</p>;
      }

      return defaultFormat ? (
        <div style={{ marginBottom: "10px" }}>
          <CustomSelect
            options={store.driverOptions}
            key={`vehiclerouterid_driver_${vehicleRoute._id}_${vehicleRoute.drivers[0]._id}`}
            id={`vehiclerouterid_driver_${vehicleRoute._id}`}
            onInput={inputHandlerDriver}
            initialValue={store.driverOptions.filter((option) => vehicleRoute.drivers[0]._id === option.value)}
            initialValid={true}
          ></CustomSelect>

          <Button
            type="button"
            onClick={(event) =>
              saveDriverHandler(event, `vehiclerouterid_driver_${vehicleRoute._id}`, vehicleRoute._id)
            }
            disabled={savingVehicleRouteId === vehicleRoute._id}
          >
            Kaydet
          </Button>
        </div>
      ) : (
        <div>
          <p>{currentDriverName}</p>
        </div>
      );
    }
  };

  // const regionComponent = (vehicleRoute) => {
  //   let currentRegionName = "";
  //   try {
  //     currentRegionName = store.transferregionOptions.find(
  //       (transferregion) => transferregion.value === vehicleRoute.routelocations[0].transferregion
  //     ).label;
  //   } catch {
  //     return <p>Bölge bulunamadı.</p>;
  //   }
  //   if (!currentRegionName) {
  //     return <p>Bölge bulunamadı.</p>;
  //   }

  //   return (
  //     <div>
  //       <p>{currentRegionName}</p>
  //     </div>
  //   );
  // };

  const downloadExcel = () => {
    if (schoolType === "1") {
      let data = calculatedTransfers.map((transfer) => ({
        "Transfer Tarihi": transfer.transferInfo.transferdate,
        "Transfer İsmi": transfer.transferInfo.name,
        "Transfer Tipi": transfer.route.type.toString() === "100" ? "Toplama" : "Dağıtma",
        Bölge: store.transferregionOptions.find(
          (transferregion) => transferregion.value === transfer.route.routelocations[0].transferregion
        ).label,
        "Transfer Saati": transfer.transferInfo.transfertime.split(" ")[4].substring(0, 5),
        Sürücü: transfer.route.driver[0]?.name || "-",
        Yolcular: transfer?.people.join(", "),
        "Yolcu Sayısı": transfer?.people?.length || 0,
      }));

      const worksheet = XLSX.utils.json_to_sheet(data);

      const MAX_COLUMN_WIDTH = 40; // Set the maximum width in characters
      const columns = Object.keys(data[0]);
      worksheet["!cols"] = columns.map((col, index) => {
        const maxLength = Math.max(
          ...data.map((row) => (row[col] ? row[col].toString().length : 0)), // Length of each cell in the column
          col.length // Length of the header
        );
        return { wch: Math.min(maxLength + 2, MAX_COLUMN_WIDTH) }; // Apply max width limit
      });

      // Style headers (first row)
      // const range = XLSX.utils.decode_range(worksheet["!ref"]);
      // for (let col = range.s.c; col <= range.e.c; col++) {
      //   const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col }); // First row
      //   if (!worksheet[cellAddress]) continue;

      //   worksheet[cellAddress].s = {
      //     font: {
      //       bold: true, // Make font bold
      //     },
      //   };
      // }

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, initDateSelected + " - " + finishDateSelected);

      XLSX.writeFile(workbook, "Servistesin_Transfer_Raporu.xlsx");
    } else {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Transfer Raporu");

      // Define columns
      worksheet.columns = [
        { header: "Transfer Tarihi", key: "transferDate", width: 15 },
        { header: "Transfer İsmi", key: "transferName", width: 20 },
        { header: "Transfer Tipi", key: "transferType", width: 15 },
        { header: "GİDİŞ", key: "departureTime", width: 10 },
        { header: "GELİŞ", key: "arrivalTime", width: 10 },
        { header: "Yolcular", key: "passengers", width: 40 }, // Wide column for multi-line text
        { header: "Sürücü", key: "driver", width: 15 },
      ];

      // Add rows
      calculatedTransfers.forEach((transfer) => {
        worksheet.addRow({
          transferDate: transfer.transferInfo.transferdate,
          transferName: transfer.transferInfo.name,
          transferType: transfer.route.type.toString() === "100" ? "Toplama" : "Dağıtma",
          departureTime:
            transfer.route.type.toString() === "100"
              ? transfer.transferInfo.transfertime.split(" ")[4].substring(0, 5)
              : "",
          arrivalTime:
            transfer.route.type.toString() === "100"
              ? ""
              : transfer.transferInfo.transfertime.split(" ")[4].substring(0, 5),

          passengers: transfer?.people.join("\n"), // Multi-line text inside a single cell
          driver: transfer.route.drivers[0]?.name || "-",
        });
      });

      // ✅ Enable "Wrap Text" for the "Yolcular" column (F)
      worksheet.getColumn("passengers").alignment = { wrapText: true };

      worksheet.eachRow((row) => {
        row.eachCell((cell) => {
          cell.alignment = { vertical: "middle", wrapText: true };
        });
      });

      // Save the file
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "Servistesin_Transfer_Raporu.xlsx";
        link.click();
      });
    }
  };

  if (
    (isLoading || !store.vehicleRoutes || !store.studentOptions || !store.schoolOptions || !today) &&
    !savingVehicleRouteId
  ) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <form>
        <Grid
          sx={{
            padding: "16px",
            fontsize: 16,
            flexGrow: 1,
            display: "flex",
            flexDirection: { xs: "row", md: "row" },
            alignItems: "center",
            justifyContent: "space-between",
            textAlign: "left",
            width: "100%",
            id: "report-receipts-container",
          }}
          container
          spacing={1}
        >
          <Grid item xs={3}>
            <CustomSelect
              options={store.schoolOptions}
              id="school"
              onInput={inputHandler}
              label="Kurum"
              initialValue={store.schoolOptions.find(
                (option) => option.value.toString() === "62a7b93b180c51001643701d"
              )}
              validators={[VALIDATOR_REQUIRE()]}
              initialValid={true}
            ></CustomSelect>
          </Grid>

          <Grid item xs={1}>
            <DatePicker
              id="datestarter"
              onInput={inputHandler}
              label="Başlangıç Tarihi"
              errorText="Başlangıç tarihi seçiniz."
              initialValid={true}
              initialValue={initDateSelected}
              validators={[VALIDATOR_REQUIRE()]}
            ></DatePicker>
          </Grid>

          <Grid item xs={1}>
            <DatePicker
              id="datefinisher"
              initialValid={true}
              onInput={inputHandler}
              label="Bitiş Tarihi"
              errorText="Bitiş tarihi seçiniz."
              initialValue={finishDateSelected}
              validators={[VALIDATOR_REQUIRE()]}
            ></DatePicker>
          </Grid>

          <Grid item xs={2}>
            <div style={{ position: "relative", top: "8px" }}>
              <Button type="button" disabled={!formState.isValid} onClick={createReport}>
                Rapor Oluştur
              </Button>
            </div>
          </Grid>

          <Grid item xs={2}>
            <div style={{ position: "relative", top: "8px" }}>
              <Button type="button" onClick={downloadExcel} disabled={!calculatedTransfers.length}>
                Raporu İndir
              </Button>
            </div>
          </Grid>
          {/* 
          <Grid item xs={4}>
            <div style={{ position: "relative", float: "right", top: "13px" }}>
              <Button type="button" onClick={() => setDefaultFormat(!defaultFormat)}>
                Format Değiştir
              </Button>
              {!defaultFormat && (
                <Button type="button" onClick={() => window.print()}>
                  Yazdır
                </Button>
              )}
            </div>
          </Grid> */}
        </Grid>
      </form>

      <div id="printableArea" style={{ display: "flex", flex: "1", flexDirection: "row" }}>
        <div style={{ width: "100%" }}>
          <table style={{ borderCollapse: `${defaultFormat ? "inherit" : "collapse"}` }}>
            <tbody>
              <tr>
                <th style={{ width: "100px" }}>Tarih</th>
                <th style={{ width: "200px" }}>İsim</th>
                <th style={{ width: "70px" }}>Havaalanı Saati</th>
                <th style={{ width: "200px" }}>Yolcular</th>
                <th style={{ width: "200px" }}>Sürücü</th>
                <th style={{ width: "200px" }}>Transfer</th>
              </tr>

              {calculatedTransfers.map((transfer, index) => (
                <tr
                  key={transfer.route._id + "asd"}
                  style={{
                    backgroundColor: defaultFormat ? "#dddddd" : index % 2 === 0 ? "#dddddd" : "#aaaaaa",
                    textAlign: "center",
                    position: "relative",
                    left: `${transfer.transferInfo.type.toString() === "100" || !defaultFormat ? "0" : "720px"}`,
                    borderBottom: `${defaultFormat ? "none" : "1px solid black"}`,
                  }}
                >
                  <td>{transfer.transferInfo.transferdate}</td>
                  <td>{transfer.transferInfo.name}</td>
                  <td>{transfer.transferInfo.transfertime.split(" ")[4].substring(0, 5)}</td>
                  <td>{peopleComponent(transfer.people)}</td>
                  <td>{driverComponent(transfer.route)}</td>
                  <td>{transfer.route.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TransferReport;
